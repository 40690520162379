<template>
  <div v-if="dataResponse!=null">
    <app-timeline>
      <app-timeline-item
        v-for="(item , index) in dataResponse['log']"
        :key="index"
        :title="item.paymentStatus | firstStringUpper"
        :subtitle="item.userId !=null ? $t('Member'):$t('Partner')"
        :time="item.updated | formatDateTime"
        :variant="paymentStatusColor(item.paymentStatus)"
        :image="item.file ? item.file.path ? item.file.path : null : null"
      />
    </app-timeline>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  props: {
    dataResponse: {
      type: Object,
      required: true,
    },
  },
  methods: {
    paymentStatusColor(data) {
      if (data === 'paid') {
        return 'primary'
      }
      if (data === 'pending') {
        return 'warning'
      }
      if (data === 'success') {
        return 'success'
      }
      if (data === 'cancel') {
        return 'danger'
      }
      if (data === 'delete') {
        return 'danger'
      }
      if (data === 'error') {
        return 'danger'
      }
      return 'dark'
    },
  },
}
</script>
