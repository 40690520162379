<template>

  <b-modal
    :visible="isModalActive"
    :title="$t('Confirm Payment')"
    ok-title="Accept"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-overlay
      :show="dataLoading"
      rounded="sm"
    >
      <b-tabs
        content-class="pt-1"
        fill
      >
        <b-tab
          v-if="dataResponse!=null"
          :title="$t('Time line')"
        >
          <timeline :data-response="dataResponse" />
        </b-tab>
      </b-tabs>
    </b-overlay>

    <template #modal-footer>
      <div class="w-100">
        <p class="float-left mb-0" />
        <div
          v-if="dataResponse!=null"
          class="float-right"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class=""
            @click="$emit('update:is-modal-active', false)"
          >
            {{ $t('Close') }}
          </b-button>
        </div>

      </div>
    </template>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
  BButton,

  BOverlay,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import Timeline from './widgets/Timeline.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BOverlay,
    Timeline,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      dataResponse: null,
      dataLoading: false,
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id,
          } = this.data
          this.dataId = _id
          this.viewData()
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.dataResponse = null
    },
    viewData() {
      this.dataLoading = true
      store
        .dispatch(`${this.storeModuleName}/view`, { id: this.dataId })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.dataResponse = result.data.data
          this.dataLoading = false

        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.dataLoading = false

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
